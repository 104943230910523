* {
  box-sizing: border-box;
  height: "100%";
  margin: 0;
  padding: 0;
  font-family: Lexend, Helvetica;
}

.landing-page {
  background-color: #f7f9ff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-width: 320px;
  display: flex;
  overflow-x: hidden;
}

.landing-page .div {
  background-color: #f7f9ff;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.landing-page .content {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  left: 0;
}

.landing-page .content > * {
  margin-bottom: 190px;
}

.landing-page .content > :last-child {
  margin-bottom: 0;
}

.landing-page .background-bottom {
  width: 90%;
  height: auto;
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
}

.landing-page .img {
  width: 90%;
  height: auto;
  margin-bottom: 20px;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
}

.landing-page .o-que-fazemos {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  margin-top: 50px;
  display: flex;
  position: relative;
}

.landing-page .content-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page .text-wrapper {
  color: #163e4c;
  letter-spacing: 0;
  text-align: center;
  height: 53px;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.landing-page .onde-a-paixa-o-por {
  color: #828282;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  max-width: 80%;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.landing-page .itens {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  position: relative;
}

.landing-page .element {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 30px;
  display: flex;
  position: relative;
}

.landing-page .icon {
  width: 60px !important;
  height: 60px !important;
  position: relative !important;
}

.landing-page .text-wrapper-2 {
  color: #163e4c;
  letter-spacing: 0;
  text-align: center;
  width: fit-content;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.landing-page .p {
  color: #828282;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.landing-page .element-2 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: flex;
  position: relative;
}

.landing-page .element-3 {
  background-color: #163e4c;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page .element-3 .text {
  margin-top: 60px;
  margin-left: 150px;
  padding: 20px;
}

.landing-page .frame {
  background-color: var(--verde-escuro);
  width: 100%;
  position: absolute;
  top: 0;
  left: 1px;
}

.landing-page .overlap-group {
  background-image: url("kal-visuals-ak4ipnyipnu-unsplash-1.ef38aa10.png");
  background-position: 50%;
  background-size: cover;
  height: 703px;
  position: relative;
}

.landing-page .VERDE {
  background-color: var(--verde-escuro);
  opacity: .7;
  width: 100%;
  height: 703px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .ARABESCO {
  width: 100%;
  height: 703px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .text {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.landing-page .text-wrapper-3 {
  color: #fff;
  letter-spacing: 0;
  width: 480px;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.landing-page .wellny-e-se-encantar {
  color: #fff;
  letter-spacing: 0;
  width: 480px;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.landing-page .text-wrapper-4 {
  color: #ea7526;
  letter-spacing: 0;
  width: 480px;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.landing-page .button-instance {
  flex: none !important;
  width: 197px !important;
}

.button .placeholder {
  margin-right: 20px;
}

.landing-page .screenshots {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  padding: 20px;
  display: flex;
  position: relative;
}

.landing-page .element-4 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page .text-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  margin-left: 140px;
  display: flex;
  position: relative;
}

.landing-page .text-wrapper-5 {
  color: var(--text-main);
  letter-spacing: 0;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.frame-3 .text-wrapper-5 {
  line-height: 3;
}

.landing-page .img, .landing-page .background-bottom {
  width: 79%;
}

.landing-page .text-wrapper-6 {
  color: var(--cinza-escuro);
  letter-spacing: 0;
  width: 480px;
  padding-right: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.landing-page .images {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 308px;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.landing-page .image {
  object-fit: cover;
  position: relative;
}

.landing-page .slider {
  width: 85.42px;
  height: 7.41px;
  position: relative;
}

.landing-page .element-5 {
  background-color: var(--cinza-claro);
  border-radius: 9px;
  width: 8px;
  height: 7px;
  position: absolute;
  top: 0;
  left: 77px;
  transform: rotate(180deg);
}

.landing-page .element-6 {
  background-color: var(--cinza-claro);
  border-radius: 9px;
  width: 8px;
  height: 7px;
  position: absolute;
  top: 0;
  left: 56px;
  transform: rotate(180deg);
}

.landing-page .element-7 {
  background-color: var(--laranja);
  border-radius: 7px;
  width: 44px;
  height: 7px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.landing-page .frame-2 {
  width: 418px;
  height: 431px;
  margin: 0 auto;
  display: block;
}

.landing-page .text-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  display: flex;
  position: relative;
}

.landing-page .text-wrapper-7 {
  color: var(--text-main);
  letter-spacing: 0;
  width: 480px;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.landing-page .frame-3 {
  margin: 0 auto;
  display: block;
}

.landing-page .text-wrapper-8 {
  color: var(--verde-escuro);
  letter-spacing: 0;
  width: fit-content;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.landing-page .content-3 {
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 1px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page .div-2 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 1080px;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.landing-page .foto-rogerio {
  width: 315px;
  height: 315px;
  position: relative;
}

.landing-page .text-4 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  display: flex;
  position: relative;
}

.landing-page .text-wrapper-9 {
  color: var(--verde-escuro);
  letter-spacing: 0;
  align-self: stretch;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.landing-page .fisioterapeuta {
  color: var(--cinza-escuro);
  letter-spacing: 0;
  align-self: stretch;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.landing-page .text-wrapper-10 {
  color: var(--verde-escuro);
  letter-spacing: 0;
  text-align: right;
  align-self: stretch;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.landing-page .fisioterapeuta-2 {
  color: var(--cinza-escuro);
  letter-spacing: 0;
  text-align: right;
  align-self: stretch;
  height: 230px;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.landing-page .foto-marcelo {
  object-fit: cover;
  width: 315px;
  height: 315px;
  position: relative;
}

.landing-page .pricing-plans {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 55px;
  width: 100%;
  padding: 88px 0 0;
  display: flex;
  position: relative;
}

.landing-page .text-wrapper-11 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 620px;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.landing-page .lorem-ipsum-dolor {
  color: var(--cinza-claro);
  letter-spacing: 0;
  text-align: center;
  width: 1060px;
  height: 53px;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.landing-page .plans {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page .gratuito {
  box-shadow: var(--card-shadow);
  background-color: #ffffff4c;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 344px;
  height: 443px;
  padding: 32px 17px;
  display: flex;
  position: relative;
}

.landing-page .preo {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page .div-3 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: fit-content;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.landing-page .frame-4 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.landing-page .span {
  color: #fff;
  letter-spacing: 0;
  font-size: 30px;
  font-weight: 700;
}

.landing-page .text-wrapper-12 {
  font-size: 60px;
}

.landing-page .text-wrapper-13 {
  color: #8693b9;
  letter-spacing: 0;
  text-align: center;
  width: fit-content;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.landing-page .text-wrapper-14 {
  color: #fff;
  letter-spacing: 0;
  width: fit-content;
  margin-left: 10px;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.landing-page .splitter {
  background-color: #ea7526;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.landing-page .diferenciais {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8px;
  width: 279px;
  display: flex;
  position: relative;
}

.landing-page .text-wrapper-14 {
  color: #fff;
  letter-spacing: 0;
  width: fit-content;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.landing-page .name {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page .bullet-check {
  width: 22px !important;
  height: 22px !important;
  position: relative !important;
}

.landing-page .agendamento-com {
  color: #fff;
  letter-spacing: 0;
  background: none;
  border: none;
  width: fit-content;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.landing-page .gratuito-2 {
  box-shadow: var(--card-shadow);
  background-color: #fff;
  border-radius: 20px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  padding: 40px 24px;
  display: inline-flex;
  position: relative;
}

.landing-page .div-4 {
  color: #163e4c;
  letter-spacing: 0;
  text-align: center;
  width: fit-content;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.landing-page .text-wrapper-16 {
  color: #163e4c;
  letter-spacing: 0;
  font-size: 30px;
  font-weight: 700;
}

.landing-page .text-wrapper-17 {
  color: var(--text-secondary);
  letter-spacing: 0;
  text-align: center;
  width: fit-content;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.landing-page .diferenciais-2, .landing-page .diferenciais-3 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page .text-wrapper-18 {
  color: var(--cinza-escuro);
  letter-spacing: 0;
  width: fit-content;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.landing-page .name-2, .landing-page .name-3 {
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.landing-page .text-wrapper-19 {
  color: var(--laranja);
  letter-spacing: 0;
  width: fit-content;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.landing-page .text-wrapper-20 {
  color: var(--preto);
  letter-spacing: 0;
  width: fit-content;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.landing-page .gratuito-3 {
  box-shadow: var(--card-shadow);
  background-color: #ffffff4c;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: fit-content;
  height: 443px;
  padding: 32px 17px;
  display: inline-flex;
  position: relative;
}

.landing-page .footer {
  background-color: #0000;
  border-style: solid none none;
  border-color: #ffffff4c;
  border-top-width: 2px;
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 1082px;
  padding: 40px 0 0;
  display: flex;
  position: relative;
}

.landing-page .div-5 {
  flex: none;
  align-items: center;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.landing-page .social-buttons {
  flex: none;
  height: 30px;
  position: relative;
}

.landing-page .div-6 {
  width: 100%;
  height: 815px;
}

.landing-page .div-7 {
  flex: none;
  align-items: center;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.landing-page .overlap-group-2 {
  width: 100%;
  height: 815px;
  display: flex;
}

.landing-page .rectangle {
  object-fit: cover;
  width: 100%;
  height: 271px;
  position: absolute;
  top: 544px;
  left: 0;
}

.landing-page .rectangle-2 {
  object-fit: cover;
  width: 100%;
  height: 798px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .mask-group {
  width: 100%;
  height: 798px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .text-block {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 49px;
  display: flex;
}

.landing-page .text-block > * {
  margin: 0 7em;
}

.landing-page .text-5 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.landing-page .cuidado-que-evolui {
  color: #fff;
  width: 550px;
  font-size: 3.125rem;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.landing-page .text-wrapper-21 {
  color: var(--text-secondary);
  letter-spacing: 0;
  width: 550px;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.landing-page .image-2 {
  z-index: 1;
  height: auto;
  margin-top: 10%;
  margin-left: 8%;
}

@media (width <= 1024px) {
  .landing-page .img, .landing-page .background-bottom, .landing-page .frame-3 {
    width: 100%;
  }

  .landing-page .image-2 {
    height: 60%;
    margin-top: 20%;
    margin-left: 1%;
  }

  .landing-page .onde-a-paixa-o-por {
    max-width: 70%;
    margin: 0 auto;
  }

  .landing-page .itens {
    flex-direction: row;
  }

  .landing-page .element, .landing-page .element-2, .landing-page .element-3 {
    max-width: calc(150% - 20px);
  }

  .landing-page .frame, .landing-page .text {
    width: 100%;
  }

  #section-3 {
    margin-top: 50px;
  }

  .landing-page .frame-2 {
    width: 40%;
  }

  .landing-page .foto-marcelo, .landing-page .foto-rogerio {
    width: 250px;
    height: auto;
  }

  .landing-page .div-2 {
    padding: 0 73px;
  }

  .landing-page .content-3 {
    margin-top: 30px;
  }

  .frame-3 .text-wrapper-5 {
    margin-left: 30px;
  }
}

@media (width <= 768px) {
  .landing-page .element-4, .landing-page #section-3 {
    flex-direction: row;
    justify-content: end;
    align-items: center;
  }

  .landing-page .text-wrapper-5, .landing-page #section-3 {
    font-size: 40px;
  }

  .frame-3 .text-wrapper-5 {
    margin-left: 20px;
  }

  .landing-page .text-5 {
    display: contents;
  }

  .landing-page .text-wrapper-6 {
    width: 310px;
    font-size: 16px;
  }

  .landing-page .frame-2 {
    width: 55%;
  }

  .landing-page .text-2, .landing-page .text-3 {
    margin-right: 45px;
  }

  .landing-page .foto-marcelo, .landing-page .foto-rogerio {
    width: 200px;
    height: auto;
  }

  .landing-page .fisioterapeuta-2 {
    max-width: 500px;
    height: auto;
    font-size: 16px;
  }

  .landing-page .fisioterapeuta, .text-wrapper-10, .foto-marcelo {
    max-width: 500px;
    font-size: 16px;
  }

  .landing-page .img, .landing-page .background-bottom, .landing-page .frame-3 {
    height: auto;
  }

  .landing-page .content-3 {
    justify-content: center;
    align-items: center;
  }

  .overlap-group-2 {
    text-align: left;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    display: flex;
  }

  .landing-page .text-block {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 490px;
    display: flex;
  }

  .image-2 {
    max-width: 300px;
    height: auto;
  }

  .landing-page .text-wrapper-21 {
    width: auto;
  }

  .landing-page .text-block > * {
    margin: 0;
  }

  .landing-page .content {
    margin-top: 200px;
  }

  .landing-page .text-2 {
    margin-left: 10px;
  }

  .landing-page .o-que-fazemos {
    bottom: 130px;
  }

  .landing-page .text-wrapper-6 {
    width: 350px;
  }
}

@media (width <= 426px) {
  .landing-page .cuidado-que-evolui {
    width: auto;
    font-size: 35px;
  }

  .landing-page .text-wrapper-21 {
    font-size: 15px;
  }

  .landing-page .text-wrapper {
    font-size: 25px;
  }

  .landing-page .image-2 {
    height: 35%;
    margin-top: 5%;
  }

  .landing-page .itens {
    flex-direction: column;
  }

  .itens {
    gap: 10px;
  }

  .landing-page .itens .p, .landing-page .onde-a-paixa-o-por {
    font-size: 14px;
  }

  .landing-page .element-3 .text {
    margin-left: auto;
  }

  .landing-page .text-wrapper-3 {
    font-size: 20px;
  }

  .landing-page .wellny-e-se-encantar {
    width: auto;
    font-size: 16px;
  }

  .landing-page .text-wrapper-4 {
    width: 280px;
    font-size: 16px;
  }

  .landing-page .button {
    width: 49%;
  }

  .landing-page .image {
    width: 160px;
    height: auto;
  }

  .landing-page .frame-2 {
    width: 140px;
    height: auto;
  }

  .landing-page .text-wrapper-5, .landing-page #section-3 {
    font-size: 35px;
  }

  .landing-page .element-4, .landing-page #section-3 {
    flex-direction: column;
  }

  .landing-page .screenshots {
    margin-left: auto;
  }

  .landing-page .text-wrapper-9 {
    font-size: 13px;
  }

  .landing-page .fisioterapeuta, .text-wrapper-10, .foto-marcelo {
    max-width: 190px;
    font-size: 13px;
  }

  .landing-page .foto-marcelo, .landing-page .foto-rogerio {
    width: 100px;
  }

  .landing-page .fisioterapeuta-2 {
    max-width: 190px;
    font-size: 13px;
  }

  .landing-page .text-wrapper-10 {
    font-size: 13px;
  }

  .overlap-group-2 {
    flex-direction: column;
    align-items: center;
    display: flex;
    position: relative;
  }

  .landing-page .text-block {
    width: 300px;
    margin-top: 150px;
  }

  .landing-page .text-block > * {
    margin: 0;
  }

  .landing-page .text-wrapper-6 p {
    max-width: 350px;
  }

  .landing-page .element-3 {
    margin-bottom: 130px;
  }

  .containerCopyright .copyrightText {
    padding-left: 20px;
  }

  .containerCopyright {
    text-align: center;
    flex-direction: column;
  }

  .containerCopyright .copyrightText {
    margin-top: 20px;
    padding-left: 0;
  }

  .landing-page .image, .landing-page .frame-2 {
    align-items: center;
    margin-top: 40px;
    margin-right: 40px;
    display: flex;
  }

  .landing-page .content {
    margin-top: 0;
  }

  .landing-page .o-que-fazemos {
    bottom: 20px;
  }

  .landing-page .background-bottom {
    transform-origin: top;
    object-fit: cover;
    width: 108%;
    height: auto;
    transform: scaleY(2.9);
  }

  .landing-page .foto-marcelo {
    margin-top: 15px;
  }

  .landing-page .img, .landing-page .background-bottom, .landing-page .frame-3, .landing-page .content-3 {
    bottom: 95px;
  }

  .frame-3 .text-wrapper-5 {
    bottom: 89px;
  }

  .landing-page .text-wrapper-10 {
    margin-top: 15px;
  }
}

@media (width <= 320px) {
  .landing-page .cuidado-que-evolui {
    width: auto;
    font-size: 25px;
  }

  .landing-page .element-3 {
    margin-bottom: 180px;
  }

  .landing-page .text-5 {
    margin: 0 2em;
  }

  .landing-page .text-wrapper-21 {
    width: 270px;
    font-size: 14px;
  }

  .landing-page .text-wrapper {
    font-size: 24px;
  }

  .landing-page .onde-a-paixa-o-por {
    font-size: 13px;
  }

  .landing-page .itens {
    flex-direction: column;
  }

  .itens {
    gap: 10px;
  }

  .landing-page .itens .p {
    font-size: 12px;
  }

  .landing-page .element-3 .text {
    margin-left: auto;
  }

  .landing-page .text-wrapper-3 {
    font-size: 13px;
  }

  .landing-page .wellny-e-se-encantar, .landing-page .text-wrapper-4 {
    width: 280px;
    font-size: 13px;
  }

  .landing-page .button {
    width: 70%;
  }

  .landing-page .element-4, .landing-page #section-3 {
    flex-direction: column;
  }

  .landing-page .text-wrapper-5, .landing-page #section-3 {
    font-size: 30px;
  }

  .landing-page .text-wrapper-6 {
    max-width: 80%;
    font-size: 13px;
  }

  .landing-page .image {
    width: 160px;
    height: auto;
  }

  .landing-page .frame-2 {
    width: 130px;
    height: auto;
    margin: inherit;
    margin-right: 200px;
    display: flex;
    position: relative;
  }

  .landing-page .images {
    margin: inherit;
    align-items: self-start;
    display: flex;
    position: relative;
  }

  .landing-page .text-wrapper-9 {
    font-size: 13px;
  }

  .landing-page .fisioterapeuta, .text-wrapper-10, .foto-marcelo {
    max-width: 190px;
    font-size: 13px;
  }

  .landing-page .foto-marcelo, .landing-page .foto-rogerio {
    width: 100px;
  }

  .landing-page .fisioterapeuta-2 {
    max-width: 190px;
    font-size: 13px;
  }

  .landing-page .text-wrapper-10 {
    font-size: 13px;
  }

  .landing-page .background-bottom {
    margin-bottom: 120px;
  }

  .landing-page .img {
    width: 190%;
    margin-bottom: 0;
  }

  .containerCopyright .copyrightText {
    padding-left: 17px;
    padding-right: 27px;
  }

  .landing-page .image-2 {
    margin-top: 6%;
  }

  .landing-page .text-2 {
    margin-left: 10px;
  }

  .landing-page .o-que-fazemos {
    bottom: 50px;
  }

  .landing-page .background-bottom {
    transform-origin: center;
    object-fit: cover;
    width: 100%;
    height: auto;
    transform: scale(2.2);
  }

  .landing-page .text-wrapper-5, .landing-page #section-3 {
    width: 60%;
  }
}

.button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  max-width: 306px;
  padding: 16px;
  display: flex;
  position: relative;
}

.button .vuesax-linear-arrow {
  width: 20px !important;
  height: 20px !important;
  position: relative !important;
}

.button .placeholder {
  letter-spacing: 0;
  width: fit-content;
  margin-top: -1px;
  font-family: Lexend, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

@media only screen and (width <= 576px) {
  .button {
    width: 100%;
    padding: 10px;
    font-size: 3vw;
  }
}

.button.dir {
  justify-content: space-between;
}

.button.esq {
  border-color: var(--laranja);
  justify-content: space-between;
}

.button.on {
  background-color: var(--laranja);
  border-radius: 51px;
}

.button.secondary {
  border: 1px solid;
}

.button.off {
  border-radius: 42px;
  gap: 8px;
}

.button.secondary.dir {
  border-color: var(--laranja);
}

.button.secondary.off {
  border-color: var(--cinza-escuro);
}

.button.primary.off.off {
  background-color: var(--cinza-claro);
}

.button.esq .placeholder {
  color: var(--laranja);
}

.button.off .placeholder {
  color: var(--cinza-escuro);
}

.button.secondary.dir .placeholder {
  color: var(--laranja);
}

.button.primary.dir.on .placeholder {
  color: var(--white);
}

.button-group-container {
  gap: 20px;
  display: flex;
}

.button-group-button {
  color: #000;
  cursor: pointer;
  background-color: #f9f6f2;
  border: 1px solid #000;
  border-radius: 5px;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.button-group-content {
  flex-direction: column;
  align-items: flex-start;
  margin-left: 26px;
  display: flex;
}

.button-group-text-paragrafo {
  margin: 0;
  font-size: .5rem;
  line-height: 1.4;
}

.button-group-text {
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
}

.button-group-logo {
  width: 22px;
  height: auto;
  position: absolute;
  left: 10px;
}

@media only screen and (width <= 375px) {
  .button-group-button {
    padding: 6px;
    font-size: .8rem;
  }

  .button-group-content {
    margin-left: 15px;
  }

  .button-group-text-paragrafo {
    font-size: .7rem;
  }

  .button-group-text {
    font-size: .8rem;
  }

  .button-group-logo {
    width: 18px;
    left: 6px;
  }
}

@media only screen and (width <= 320px) {
  .button-group-button {
    padding: 5px 15px;
  }

  .button-group-content {
    margin-left: 10px;
  }

  .button-group-text-paragrafo {
    font-size: .5rem;
  }

  .button-group-text {
    font-size: .9rem;
  }

  .button-group-logo {
    width: 16px;
    left: 4px;
  }
}

.vuesax-linear-arrow-circle-right-4 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.feature {
  align-items: center;
  gap: 6px;
  display: flex;
}

.pricing-plans {
  flex-direction: column;
  align-items: center;
  gap: 55px;
  width: 100%;
  padding: 88px 0;
  display: flex;
}

.section-title {
  color: #000;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

.section-description {
  color: var(--cinza-escuro);
  text-align: center;
  width: 80%;
  max-width: 1060px;
  font-size: 18px;
}

.plans {
  justify-content: center;
  gap: 24px;
  display: flex;
}

.plan-card {
  text-align: center;
  width: 344px;
  box-shadow: var(--card-shadow);
  background-color: #ffffff4d;
  border-radius: 20px;
  padding: 32px 17px;
}

.highlighted {
  background-color: #fff;
}

.price-section .title {
  color: #000;
  font-size: 30px;
  font-weight: bold;
}

.price {
  justify-content: center;
  align-items: baseline;
  gap: 4px;
  font-size: 60px;
  display: flex;
}

.price-subtext {
  color: #8693b9;
  font-size: 16px;
}

.divider {
  background-color: #ea7526;
  height: 1px;
  margin: 16px 0;
}

.features .access-text {
  color: #fff;
  margin-bottom: 8px;
  font-size: 14px;
}

.feature {
  flex-wrap: "wrap";
  align-items: center;
  gap: 6px;
  display: flex;
}

.bullet-icon {
  width: 20px;
  height: 20px;
}

.text-wrapper-15 {
  color: #fff;
  letter-spacing: 0;
  cursor: pointer;
  width: fit-content;
  margin-top: -1px;
  font-family: Lexend, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  position: relative;
}

@media only screen and (width <= 1024px) {
  .text-wrapper-15 {
    cursor: pointer;
    font-size: 14px;
  }
}

@media only screen and (width <= 576px) {
  .text-wrapper-15 {
    cursor: pointer;
    font-size: 11px;
  }
}

@media only screen and (width <= 768px) {
  .design-component-instance-node {
    display: none;
  }

  .logo {
    width: 130px;
    max-width: 159px;
    height: auto;
    max-height: 65px;
  }
}

@media only screen and (width <= 694px) {
  .design-component-instance-node {
    display: none;
  }

  .menu.visible, .menu.hidden {
    flex-direction: column;
  }

  .logo {
    align-self: center;
    width: 130px;
    max-width: 159px;
    height: auto;
    max-height: 65px;
  }
}

@media (width >= 1440px) {
  body.menu-active .landing-page .img {
    clip-path: inset(0 0 50%) !important;
    object-fit: cover !important;
    width: 79% !important;
    height: auto !important;
    position: absolute !important;
    bottom: 0 !important;
  }

  body.menu-active .landing-page .div {
    height: 3600px;
  }
}

@media (width <= 1024px) {
  body.menu-active .landing-page .img {
    clip-path: inset(0 0 50%) !important;
    object-fit: cover !important;
    width: 100% !important;
    height: auto !important;
    position: absolute !important;
    bottom: 0 !important;
  }

  body.menu-active .landing-page .div {
    height: 4000px;
  }
}

@media (width <= 768px) {
  body.menu-active .landing-page .img {
    clip-path: inset(0 0 40%) !important;
    object-fit: cover !important;
    width: 100% !important;
    height: auto !important;
    position: absolute !important;
    bottom: 0 !important;
  }

  body.menu-active .landing-page .div {
    height: 4300px;
  }
}

@media (width <= 426px) {
  body.menu-active .landing-page .img {
    clip-path: inset(0 0 0%) !important;
    object-fit: cover !important;
    width: 100% !important;
    height: auto !important;
    position: absolute !important;
    bottom: 0 !important;
  }

  body.menu-active .landing-page .div {
    height: 4660px;
  }
}

@media (width <= 320px) {
  body.menu-active .landing-page .img {
    clip-path: inset(0 0 0%) !important;
    object-fit: cover !important;
    width: 100% !important;
    height: auto !important;
    position: absolute !important;
    bottom: 0 !important;
  }

  body.menu-active .landing-page .div {
    height: 4600px;
  }
}

@media only screen and (width <= 320px) {
  .menu .div-5 {
    gap: 1rem;
  }
}

.menu {
  backdrop-filter: blur(24px) brightness();
  z-index: 2;
  background-color: #163e4cb2;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  width: 100%;
  min-width: 320px;
  padding: 1.5rem 5%;
  transition: opacity .5s;
  display: flex;
  position: fixed;
  top: 0;
}

.menu.hidden {
  opacity: 0;
  pointer-events: none;
}

.menu.visible {
  opacity: 1;
  pointer-events: auto;
}

.div-5 {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.logo {
  width: auto;
  max-width: 159px;
  height: auto;
  max-height: 65px;
}

.design-component-instance-node {
  width: 100%;
  max-width: 197px;
}

.box-SocialButtons {
  gap: 15px;
  display: flex;
}

@media only screen and (width <= 768px) {
  .design-component-instance-node {
    display: none;
  }

  .logo {
    width: 130px;
    max-width: 159px;
    height: auto;
    max-height: 65px;
  }
}

@media only screen and (width <= 694px) {
  .design-component-instance-node {
    display: none;
  }

  .menu.visible, .menu.hidden {
    flex-direction: column;
  }

  .logo {
    align-self: center;
    width: 130px;
    max-width: 159px;
    height: auto;
    max-height: 65px;
  }
}
/*# sourceMappingURL=index.e18b1374.css.map */
