.pricing-plans {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 55px;
  padding: 88px 0;
  width: 100%;
}

.section-title {
  font-size: 40px;
  color: #000;
  font-weight: bold;
  text-align: center;
}

.section-description {
  font-size: 18px;
  color: var(--cinza-escuro);
  text-align: center;
  width: 80%;
  max-width: 1060px;
}

.plans {
  display: flex;
  gap: 24px;
  justify-content: center;
}

.plan-card {
  background-color: rgba(255, 255, 255);
  border-radius: 20px;
  padding: 32px 17px;
  text-align: center;
  width: 344px;
  box-shadow: var(--card-shadow);
}

.highlighted {
  background-color: #ffffff;
}

.price-section .title {
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
}

.price {
  font-size: 60px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 4px;
}

.price-subtext {
  color: #8693b9;
  font-size: 16px;
}

.divider {
  height: 1px;
  background-color: #ea7526;
  margin: 16px 0;
}

.features .access-text {
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 8px;
}

.feature {
  display: flex;
  gap: 6px;
  align-items: center;
}

.bullet-icon {
  width: 22px;
  height: 22px;
}

.pricing-plans {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 55px;
  padding: 88px 0;
  width: 100%;
}

.section-title {
  font-size: 40px;
  color: #000;
  font-weight: bold;
  text-align: center;
}

.section-description {
  font-size: 18px;
  color: var(--cinza-escuro);
  text-align: center;
  width: 80%;
  max-width: 1060px;
}

.plans {
  display: flex;
  gap: 24px;
  justify-content: center;
}

.plan-card {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  padding: 32px 17px;
  text-align: center;
  width: 344px;
  box-shadow: var(--card-shadow);
}

.highlighted {
  background-color: #ffffff;
}

.price-section .title {
  font-size: 30px;
  font-weight: bold;
  color: #000;
}

.price {
  font-size: 60px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 4px;
}

.price-subtext {
  color: #8693b9;
  font-size: 16px;
}

.divider {
  height: 1px;
  background-color: #ea7526;
  margin: 16px 0;
}

.features .access-text {
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 8px;
}

.feature {
  display: flex;
  gap: 6px;
  align-items: center;
  flex-wrap: "wrap";
}

.bullet-icon {
  width: 20px;
  height: 20px;
}
