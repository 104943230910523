* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lexend", Helvetica;
  height: "100%";
}

.landing-page {
  background-color: #f7f9ff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-width: 320px;
  overflow-x: hidden;
}

.landing-page .div {
  background-color: #f7f9ff;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.landing-page .content {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  width: 100%;
}

.landing-page .content > * {
  margin-bottom: 190px;
}

.landing-page .content > *:last-child {
  margin-bottom: 0;
}

.landing-page .background-bottom {
  position: absolute;
  bottom: 0;
  width: 90%;
  height: auto;
  transform: translateY(50%);
}

.landing-page .img {
  position: absolute;
  transform: translateY(100%);
  bottom: 0;
  width: 90%;
  height: auto;
  margin-bottom: 20px;
}

.landing-page .o-que-fazemos {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
  margin-top: 50px;
}

.landing-page .content-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.landing-page .text-wrapper {
  color: #163e4c;
  font-size: 2.5rem;
  font-weight: 700;
  height: 53px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.landing-page .onde-a-paixa-o-por {
  align-self: stretch;
  color: #828282;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
}

.landing-page .itens {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  position: relative;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

.landing-page .element {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 30px;
  position: relative;
}

.landing-page .icon {
  height: 60px !important;
  position: relative !important;
  width: 60px !important;
}

.landing-page .text-wrapper-2 {
  color: #163e4c;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.landing-page .p {
  align-self: stretch;
  color: #828282;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.landing-page .element-2 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  justify-content: center;
  position: relative;
}

.landing-page .element-3 {
  align-items: flex-start;
  background-color: #163e4c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.landing-page .element-3 .text {
  padding: 20px;
  margin-left: 150px;
  margin-top: 60px;
}

.landing-page .frame {
  background-color: var(--verde-escuro);
  left: 1px;
  position: absolute;
  top: 0;
  width: 100%;
}

.landing-page .overlap-group {
  background-image: url(../../../static/img/kal-visuals-ak4ipnyipnu-unsplash-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 703px;
  position: relative;
}

.landing-page .VERDE {
  background-color: var(--verde-escuro);
  height: 703px;
  left: 0;
  opacity: 0.7;
  position: absolute;
  top: 0;
  width: 100%;
}

.landing-page .ARABESCO {
  height: 703px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.landing-page .text {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  position: relative;
}

.landing-page .text-wrapper-3 {
  color: #ffffff;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 480px;
}

.landing-page .wellny-e-se-encantar {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 480px;
}

.landing-page .text-wrapper-4 {
  color: #ea7526;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 480px;
}

.landing-page .button-instance {
  flex: 0 0 auto !important;
  width: 197px !important;
}

.button .placeholder {
  margin-right: 20px;
}

.landing-page .screenshots {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  padding: 20px;
}

.landing-page .element-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.landing-page .text-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  margin-left: 140px;
}

.landing-page .text-wrapper-5 {
  color: var(--text-main);
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.frame-3 .text-wrapper-5 {
  line-height: 3;
}

.landing-page .img,
.landing-page .background-bottom {
  width: 79%;
}

.landing-page .text-wrapper-6 {
  color: var(--cinza-escuro);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 480px;
  padding-right: 20px;
}

.landing-page .images {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 308px;
  margin: 0 auto;
}

.landing-page .image {
  object-fit: cover;
  position: relative;
}

.landing-page .slider {
  height: 7.41px;
  position: relative;
  width: 85.42px;
}

.landing-page .element-5 {
  background-color: var(--cinza-claro);
  border-radius: 9px;
  height: 7px;
  left: 77px;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 8px;
}

.landing-page .element-6 {
  background-color: var(--cinza-claro);
  border-radius: 9px;
  height: 7px;
  left: 56px;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 8px;
}

.landing-page .element-7 {
  background-color: var(--laranja);
  border-radius: 7px;
  height: 7px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 44px;
}

.landing-page .frame-2 {
  height: 431px;
  width: 418px;
  display: block;
  margin: 0 auto;
}

.landing-page .text-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.landing-page .text-wrapper-7 {
  color: var(--text-main);
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 480px;
}

.landing-page .frame-3 {
  display: block;
  margin: 0 auto;
}

.landing-page .text-wrapper-8 {
  color: var(--verde-escuro);
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.landing-page .content-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.landing-page .div-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: center;
  padding: 0px 32px;
  position: relative;
  width: 1080px;
}

.landing-page .foto-rogerio {
  height: 315px;
  position: relative;
  width: 315px;
}

.landing-page .text-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  position: relative;
}

.landing-page .text-wrapper-9 {
  align-self: stretch;
  color: var(--verde-escuro);
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.landing-page .fisioterapeuta {
  align-self: stretch;
  color: var(--cinza-escuro);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.landing-page .text-wrapper-10 {
  align-self: stretch;
  color: var(--verde-escuro);
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: right;
  margin-top: 10px;
}

.landing-page .fisioterapeuta-2 {
  align-self: stretch;
  color: var(--cinza-escuro);
  font-size: 18px;
  font-weight: 400;
  height: 230px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: right;
}

.landing-page .foto-marcelo {
  height: 315px;
  object-fit: cover;
  position: relative;
  width: 315px;
}

.landing-page .pricing-plans {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 55px;
  padding: 88px 0px 0px;
  position: relative;
  width: 100%;
}

.landing-page .text-wrapper-11 {
  color: #ffffff;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 620px;
}

.landing-page .lorem-ipsum-dolor {
  color: var(--cinza-claro);
  font-size: 18px;
  font-weight: 400;
  height: 53px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 1060px;
}

.landing-page .plans {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.landing-page .gratuito {
  align-items: center;
  background-color: #ffffff4c;
  border-radius: 20px;
  box-shadow: var(--card-shadow);
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 443px;
  padding: 32px 17px;
  position: relative;
  width: 344px;
}

.landing-page .preo {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.landing-page .div-3 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.landing-page .frame-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  position: relative;
}

.landing-page .span {
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
}

.landing-page .text-wrapper-12 {
  font-size: 60px;
}

.landing-page .text-wrapper-13 {
  color: #8693b9;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.landing-page .text-wrapper-14 {
  color: #ffffff;
  font-size: 8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 10px;
  position: relative;
  width: fit-content;
}

.landing-page .splitter {
  align-self: stretch;
  background-color: #ea7526;
  height: 1px;
  position: relative;
  width: 100%;
}

.landing-page .diferenciais {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 279px;
}

.landing-page .text-wrapper-14 {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.landing-page .name {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
  width: 100%;
}

.landing-page .bullet-check {
  height: 22px !important;
  position: relative !important;
  width: 22px !important;
}

.landing-page .agendamento-com {
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  padding: 0;
  position: relative;
  width: fit-content;
}

.landing-page .gratuito-2 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: var(--card-shadow);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 40px 24px;
  position: relative;
}

.landing-page .div-4 {
  color: #163e4c;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.landing-page .text-wrapper-16 {
  color: #163e4c;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
}

.landing-page .text-wrapper-17 {
  color: var(--text-secondary);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.landing-page .diferenciais-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}
.landing-page .diferenciais-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.landing-page .text-wrapper-18 {
  color: var(--cinza-escuro);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.landing-page .name-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
}
.landing-page .name-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
}

.landing-page .text-wrapper-19 {
  color: var(--laranja);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.landing-page .text-wrapper-20 {
  color: var(--preto);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.landing-page .gratuito-3 {
  align-items: center;
  background-color: #ffffff4c;
  border-radius: 20px;
  box-shadow: var(--card-shadow);
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  height: 443px;
  padding: 32px 17px;
  position: relative;
  width: fit-content;
}

.landing-page .footer {
  align-items: center;
  background-color: transparent;
  border-bottom-style: none;
  border-color: #ffffff4c;
  border-left-style: none;
  border-right-style: none;
  border-top-style: solid;
  border-top-width: 2px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 40px 0px 0px;
  position: relative;
  width: 1082px;
}

.landing-page .div-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.landing-page .social-buttons {
  flex: 0 0 auto;
  height: 30px;
  position: relative;
}

.landing-page .div-6 {
  height: 815px;
  width: 100%;
}
.landing-page .div-7 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.landing-page .overlap-group-2 {
  height: 815px;
  display: flex;
  width: 100%;
}

.landing-page .rectangle {
  height: 271px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 544px;
  width: 100%;
}

.landing-page .rectangle-2 {
  height: 798px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.landing-page .mask-group {
  height: 798px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.landing-page .text-block {
  display: flex;
  flex-direction: column;
  gap: 49px;
  align-items: flex-start;
  justify-content: center;
}

.landing-page .text-block > * {
  /* flex-grow: 1; */
  margin: 0 7em;
}

.landing-page .text-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
}

.landing-page .cuidado-que-evolui {
  color: #ffffff;
  font-size: 3.125rem;
  font-weight: 700;
  line-height: normal;
  position: relative;
  width: 550px;
}

.landing-page .text-wrapper-21 {
  color: var(--text-secondary);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 550px;
}

.landing-page .image-2 {
  height: auto;
  z-index: 1;
  margin-left: 8%;
  margin-top: 10%;
}

@media (max-width: 1024px) {
  .landing-page .img {
    width: 100%;
  }
  .landing-page .background-bottom {
    width: 100%;
  }

  .landing-page .frame-3 {
    width: 100%;
  }

  .landing-page .image-2 {
    margin-top: 20%;
    height: 60%;
    margin-left: 1%;
  }

  .landing-page .onde-a-paixa-o-por {
    max-width: 70%;
    margin: 0 auto;
  }

  .landing-page .itens {
    flex-direction: row;
  }

  .landing-page .element,
  .landing-page .element-2,
  .landing-page .element-3 {
    max-width: calc(150% - 20px);
  }

  .landing-page .frame,
  .landing-page .text {
    width: 100%;
  }

  #section-3 {
    margin-top: 50px;
  }

  .landing-page .frame-2 {
    width: 40%;
  }
  .landing-page .foto-marcelo,
  .landing-page .foto-rogerio {
    width: 250px;
    height: auto;
  }
  .landing-page .div-2 {
    padding: 0 73px;
  }

  .landing-page .content-3 {
    margin-top: 30px;
  }
  .frame-3 .text-wrapper-5 {
    margin-left: 30px;
  }
}

@media (max-width: 768px) {
  .landing-page .element-4,
  .landing-page #section-3 {
    flex-direction: row;
    justify-content: end;
    align-items: center;
  }

  .landing-page .text-wrapper-5,
  .landing-page #section-3 {
    font-size: 40px;
  }
  .frame-3 .text-wrapper-5 {
    margin-left: 20px;
  }

  .landing-page .text-5 {
    display: contents;
  }

  .landing-page .text-wrapper-6 {
    font-size: 16px;
    width: 310px;
  }
  .landing-page .frame-2 {
    width: 55%;
  }
  .landing-page .text-2 {
    margin-right: 45px;
  }
  .landing-page .text-3 {
    margin-right: 45px;
  }

  .landing-page .foto-marcelo,
  .landing-page .foto-rogerio {
    width: 200px;
    height: auto;
  }

  .landing-page .fisioterapeuta-2 {
    height: auto;
    font-size: 16px;
    max-width: 500px;
  }

  .landing-page .fisioterapeuta,
  .text-wrapper-10,
  .foto-marcelo {
    font-size: 16px;
    max-width: 500px;
  }

  .landing-page .img,
  .landing-page .background-bottom,
  .landing-page .frame-3 {
    height: auto;
  }

  .landing-page .content-3 {
    justify-content: center;
    align-items: center;
  }

  .overlap-group-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    justify-content: space-around;
  }

  .landing-page .text-block {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 490px;
    display: flex;
  }

  .image-2 {
    max-width: 300px;
    height: auto;
  }

  .landing-page .text-wrapper-21 {
    width: auto;
  }
  .landing-page .text-block > * {
    margin: 0em;
  }
  .landing-page .content {
    margin-top: 200px;
  }
  .landing-page .text-2 {
    margin-left: 10px;
  }

  .landing-page .o-que-fazemos {
    bottom: 130px;
  }
  .landing-page .text-wrapper-6 {
    width: 350px;
  }
}

@media (max-width: 426px) {
  .landing-page .cuidado-que-evolui {
    font-size: 35px;
    width: auto;
  }
  .landing-page .text-wrapper-21 {
    font-size: 15px;
  }

  .landing-page .text-wrapper {
    font-size: 25px;
  }
  .landing-page .image-2 {
    height: 35%;
    margin-top: 5%;
  }

  .landing-page .itens {
    flex-direction: column;
  }
  .itens {
    gap: 10px;
  }

  .landing-page .itens .p {
    font-size: 14px;
  }

  .landing-page .onde-a-paixa-o-por {
    font-size: 14px;
  }
  .landing-page .element-3 .text {
    margin-left: auto;
  }
  .landing-page .text-wrapper-3 {
    font-size: 20px;
  }
  .landing-page .wellny-e-se-encantar {
    font-size: 16px;
    width: auto;
  }
  .landing-page .text-wrapper-4 {
    font-size: 16px;
    width: 280px;
  }
  .landing-page .button {
    width: 49%;
  }
  .landing-page .image {
    height: auto;
    width: 160px;
  }
  .landing-page .frame-2 {
    height: auto;
    width: 140px;
  }

  .landing-page .text-wrapper-5,
  .landing-page #section-3 {
    font-size: 35px;
  }
  .landing-page .element-4,
  .landing-page #section-3 {
    flex-direction: column;
  }
  .landing-page .screenshots {
    margin-left: auto;
  }
  .landing-page .text-wrapper-9 {
    font-size: 13px;
  }
  .landing-page .fisioterapeuta,
  .text-wrapper-10,
  .foto-marcelo {
    max-width: 190px;
    font-size: 13px;
  }

  .landing-page .foto-marcelo,
  .landing-page .foto-rogerio {
    width: 100px;
  }
  .landing-page .fisioterapeuta-2 {
    max-width: 190px;
    font-size: 13px;
  }
  .landing-page .text-wrapper-10 {
    font-size: 13px;
  }

  .overlap-group-2 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .landing-page .text-block {
    width: 300px;
    margin-top: 150px;
  }
  .landing-page .text-block > * {
    margin: 0;
  }
  .landing-page .text-wrapper-6 p {
    max-width: 350px;
  }

  .landing-page .element-3 {
    margin-bottom: 130px;
  }

  .containerCopyright .copyrightText {
    padding-left: 20px;
  }
  .containerCopyright {
    flex-direction: column;
    text-align: center;
  }

  .containerCopyright .copyrightText {
    padding-left: 0;
    margin-top: 20px;
  }
  .landing-page .image,
  .landing-page .frame-2 {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-right: 40px;
  }
  .landing-page .content {
    margin-top: 0px;
  }
  .landing-page .o-que-fazemos {
    bottom: 20px;
  }

  .landing-page .background-bottom {
    width: 108%;
    height: auto;
    transform: scaleY(2.9);
    transform-origin: top;
    object-fit: cover;
  }
  .landing-page .foto-marcelo {
    margin-top: 15px;
  }

  .landing-page .img,
  .landing-page .background-bottom,
  .landing-page .frame-3,
  .landing-page .content-3 {
    bottom: 95px;
  }

  .frame-3 .text-wrapper-5 {
    bottom: 89px;
  }

  .landing-page .text-wrapper-10 {
    margin-top: 15px;
  }
}

@media (max-width: 320px) {
  .landing-page .cuidado-que-evolui {
    font-size: 25px;
    width: auto;
  }

  .landing-page .element-3 {
    margin-bottom: 180px;
  }

  .landing-page .text-5 {
    margin: 0 2em;
  }

  .landing-page .text-wrapper-21 {
    font-size: 14px;
    width: 270px;
  }
  .landing-page .text-wrapper {
    font-size: 24px;
  }

  .landing-page .onde-a-paixa-o-por {
    font-size: 13px;
  }

  .landing-page .itens {
    flex-direction: column;
  }
  .itens {
    gap: 10px;
  }

  .landing-page .itens .p {
    font-size: 12px;
  }

  .landing-page .element-3 .text {
    margin-left: auto;
  }
  .landing-page .text-wrapper-3 {
    font-size: 13px;
  }
  .landing-page .wellny-e-se-encantar {
    font-size: 13px;
    width: 280px;
  }

  .landing-page .text-wrapper-4 {
    font-size: 13px;
    width: 280px;
  }

  .landing-page .button {
    width: 70%;
  }

  .landing-page .element-4,
  .landing-page #section-3 {
    flex-direction: column;
  }
  .landing-page .text-wrapper-5,
  .landing-page #section-3 {
    font-size: 30px;
  }
  .landing-page .text-wrapper-6 {
    font-size: 13px;
    max-width: 80%;
  }
  .landing-page .image {
    height: auto;
    width: 160px;
  }

  .landing-page .frame-2 {
    height: auto;
    width: 130px;
    display: flex;
    position: relative;
    margin: inherit;
    margin-right: 200px;
  }

  .landing-page .images {
    display: flex;
    position: relative;
    margin: inherit;
    align-items: self-start;
  }

  .landing-page .text-wrapper-9 {
    font-size: 13px;
  }
  .landing-page .fisioterapeuta,
  .text-wrapper-10,
  .foto-marcelo {
    max-width: 190px;
    font-size: 13px;
  }

  .landing-page .foto-marcelo,
  .landing-page .foto-rogerio {
    width: 100px;
  }
  .landing-page .fisioterapeuta-2 {
    max-width: 190px;
    font-size: 13px;
  }
  .landing-page .text-wrapper-10 {
    font-size: 13px;
  }

  .landing-page .background-bottom {
    margin-bottom: 120px;
  }

  .landing-page .img {
    width: 190%;
    margin-bottom: 0;
  }
  .containerCopyright .copyrightText {
    padding-left: 17px;
    padding-right: 27px;
  }

  .landing-page .image-2 {
    margin-top: 6%;
  }

  .landing-page .text-2 {
    margin-left: 10px;
  }
  .landing-page .o-que-fazemos {
    bottom: 50px;
  }

  .landing-page .background-bottom {
    width: 100%;
    height: auto;
    transform: scale(2.2);
    transform-origin: center;
    object-fit: cover;
  }
  .landing-page .text-wrapper-5,
  .landing-page #section-3 {
    width: 60%;
  }
}
