.menu {
  -webkit-backdrop-filter: blur(24px) brightness(100%);
  align-items: center;
  align-self: center;
  backdrop-filter: blur(24px) brightness(100%);
  background-color: #163e4cb2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1.5rem 5%;
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 320px;
  z-index: 2;
  transition: opacity 0.5s ease;
}

.menu.hidden {
  opacity: 0;
  pointer-events: none;
}

.menu.visible {
  opacity: 1;
  pointer-events: auto;
}

.div-5 {
  align-items: center;
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  flex: 1;
}

.logo {
  height: auto;
  max-height: 65px;
  width: auto;
  max-width: 159px;
}

.design-component-instance-node {
  width: 100%;
  max-width: 197px;
}

@media only screen and (max-width: 768px) {
  .design-component-instance-node {
    display: none;
  }

  .logo {
    height: auto;
    max-height: 65px;
    width: 130px;
    max-width: 159px;
  }
}

@media only screen and (max-width: 694px) {
  .design-component-instance-node {
    display: none;
  }

  .menu.visible {
    flex-direction: column;
  }

  .menu.hidden {
    flex-direction: column;
  }

  .logo {
    height: auto;
    max-height: 65px;
    width: 130px;
    max-width: 159px;
    align-self: center;
  }
}
@media (min-width: 1440px) {
  body.menu-active .landing-page .img {
    clip-path: inset(0 0 50% 0) !important;
    width: 79% !important;
    height: auto !important;
    object-fit: cover !important;
    position: absolute !important;
    bottom: 0 !important;
  }

  body.menu-active .landing-page .div {
    height: 3600px;
  }
}

@media (max-width: 1024px) {
  body.menu-active .landing-page .img {
    clip-path: inset(0 0 50% 0) !important;
    width: 100% !important;
    height: auto !important;
    object-fit: cover !important;
    position: absolute !important;
    bottom: 0 !important;
  }

  body.menu-active .landing-page .div {
    height: 4000px;
  }
}

@media (max-width: 768px) {
  body.menu-active .landing-page .img {
    clip-path: inset(0 0 40% 0) !important;
    width: 100% !important;
    height: auto !important;
    object-fit: cover !important;
    position: absolute !important;
    bottom: 0 !important;
  }

  body.menu-active .landing-page .div {
    height: 4300px;
  }
}
@media (max-width: 426px) {
  body.menu-active .landing-page .img {
    clip-path: inset(0 0 0% 0) !important;
    width: 100% !important;
    height: auto !important;
    object-fit: cover !important;
    position: absolute !important;
    bottom: 0px !important;
  }

  body.menu-active .landing-page .div {
    height: 4660px;
  }
}

@media (max-width: 320px) {
  body.menu-active .landing-page .img {
    clip-path: inset(0 0 0% 0) !important;
    width: 100% !important;
    height: auto !important;
    object-fit: cover !important;
    position: absolute !important;
    bottom: 0px !important;
  }

  body.menu-active .landing-page .div {
    height: 4600px;
  }
}
@media only screen and (width <= 320px) {
  .menu .div-5 {
    gap: 1rem;
  }
}
