.text-wrapper-15 {
    color: #ffffff;
    font-family: "Lexend", Helvetica, sans-serif;
    font-size: 1rem; /* Use unidades relativas como rem para tornar o tamanho da fonte responsivo */
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5; /* Use um valor relativo para a altura da linha */
    margin-top: -1px;
    position: relative;
    width: fit-content;
    cursor: pointer;
}

/* Media query para telas menores */
@media only screen and (max-width: 1024px) {
    .text-wrapper-15 {
        font-size: 14px;
        cursor: pointer;
    }
}

/* Media query para telas menores, como tablets */
@media only screen and (max-width: 768px) {
    .button {
    
    }
}

/* Media query para telas menores, como celulares em modo paisagem */
@media only screen and (max-width: 576px) {
    .text-wrapper-15 {
        font-size: 11px;
        cursor: pointer;
    }
}