.button {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  padding: 16px;
  position: relative;
  max-width: 306px;
  cursor: pointer;
}

.button .vuesax-linear-arrow {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.button .placeholder {
  font-family: "Lexend", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 768px) {
  .button {
  }
}

@media only screen and (max-width: 576px) {
  .button {
    width: 100%;
    padding: 10px;
    font-size: 3vw;
  }
}

.button.dir {
  justify-content: space-between;
}

.button.esq {
  border-color: var(--laranja);
  justify-content: space-between;
}

.button.on {
  background-color: var(--laranja);
  border-radius: 51px;
}

.button.secondary {
  border: 1px solid;
}

.button.off {
  gap: 8px;
}

.button.off {
  border-radius: 42px;
}

.button.secondary.dir {
  border-color: var(--laranja);
}

.button.secondary.off {
  border-color: var(--cinza-escuro);
}

.button.primary.off.off {
  background-color: var(--cinza-claro);
}

.button.esq .placeholder {
  color: var(--laranja);
}

.button.off .placeholder {
  color: var(--cinza-escuro);
}

.button.secondary.dir .placeholder {
  color: var(--laranja);
}

.button.primary.dir.on .placeholder {
  color: var(--white);
}

.button-group-container {
  display: flex;
  gap: 20px;
}

.button-group-button {
  background-color: #f9f6f2;
  padding: 10px 10px;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  /* z-index: 10; */
}

.button-group-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 26px;
}

.button-group-text-paragrafo {
  margin: 0;
  font-size: 0.5rem;
  line-height: 1.4;
}

.button-group-text {
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
}

.button-group-logo {
  width: 22px;
  height: auto;
  position: absolute;
  left: 10px;
}
@media only screen and (max-width: 375px) {
  .button-group-button {
    padding: 6px 6px;
    font-size: 0.8rem;
  }

  .button-group-content {
    margin-left: 15px;
  }

  .button-group-text-paragrafo {
    font-size: 0.7rem;
  }

  .button-group-text {
    font-size: 0.8rem;
  }

  .button-group-logo {
    width: 18px;
    left: 6px;
  }
}

@media only screen and (max-width: 320px) {
  .button-group-button {
    padding: 5px 15px;
  }

  .button-group-content {
    margin-left: 10px;
  }

  .button-group-text-paragrafo {
    font-size: 0.5rem;
  }

  .button-group-text {
    font-size: 0.9rem;
  }

  .button-group-logo {
    width: 16px;
    left: 4px;
  }
}
