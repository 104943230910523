.menu {
  -webkit-backdrop-filter: blur(24px) brightness(100%);
  align-items: center;
  align-self: center;
  backdrop-filter: blur(24px) brightness(100%);
  background-color: #163e4cb2;
  display: flex;
  flex-wrap: wrap; /* Permite que os itens se ajustem quando o espaço é limitado */
  justify-content: space-between;
  padding: 1.5rem 5%; /* Use porcentagem para ajustar o preenchimento responsivamente */
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 320px;
  z-index: 2;
  transition: opacity 0.5s ease;
}

.menu.hidden {
  opacity: 0;
  pointer-events: none;
}

.menu.visible {
  opacity: 1;
  pointer-events: auto;
}

.div-5 {
  align-items: center;
  display: flex;
  gap: 2rem; /* Use uma unidade relativa, como rem, para o espaçamento */
  justify-content: flex-end; /* Alinhe os itens à direita */
  flex: 1; /* Ocupa todo o espaço disponível */
}

.logo {
  height: auto; /* Ajusta automaticamente a altura */
  max-height: 65px; /* Define uma altura máxima */
  width: auto; /* Ajusta automaticamente a largura */
  max-width: 159px; /* Define uma largura máxima */
}

.design-component-instance-node {
  width: 100%; /* Ocupa todo o espaço disponível */
  max-width: 197px; /* Define uma largura máxima */
}

.box-SocialButtons {
  display: flex;
  gap: 15px;
}

/* Media query para telas menores, como tablets */
@media only screen and (max-width: 768px) {
  .design-component-instance-node {
    display: none;
  }

  .logo {
    height: auto;
    max-height: 65px;
    width: 130px;
    max-width: 159px;
  }
}

@media only screen and (max-width: 694px) {
  .design-component-instance-node {
    display: none;
  }

  .menu.visible {
    flex-direction: column;
  }

  .menu.hidden {
    flex-direction: column;
  }

  .logo {
    height: auto;
    max-height: 65px;
    width: 130px;
    max-width: 159px;
    align-self: center;
  }
}
